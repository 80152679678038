import { useEffect } from 'react';
import { useFlightDataContext, FlightDataActionType } from 'src/@realtime/contexts/flight';
import { useNmtContext, NmtActionType } from 'src/@realtime/contexts/noiseMonitors';
import { usePlaybackContext, PlaybackActionType } from 'src/@realtime/contexts/playback';
import { PLAYBACK_HISTORY_MILLISECONDS } from 'src/@realtime/constants';
import { usePlaybackPruneInterval } from 'src/@realtime/hooks';

export const usePruneData = () => {
  const { dispatch: flightDispatch } = useFlightDataContext();
  const { dispatch: noiseDispatch } = useNmtContext();
  const { dispatch: playbackDispatch } = usePlaybackContext();
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const pruneThresholdTime = now - PLAYBACK_HISTORY_MILLISECONDS;
      console.log('Pruning data older than', new Date(pruneThresholdTime).toISOString());
      flightDispatch({
        type: FlightDataActionType.PRUNE_OLD_FLIGHT_DATA,
        payload: pruneThresholdTime,
      });

      noiseDispatch({
        type: NmtActionType.PRUNE_OLD_NOISE_DATA,
        payload: pruneThresholdTime,
      });

      playbackDispatch({
        type: PlaybackActionType.PRUNE_OLD_TIMESTAMPS,
        payload: pruneThresholdTime,
      });
    }, usePlaybackPruneInterval());
    return () => clearInterval(interval); // Cleanup on unmount
  }, [flightDispatch, noiseDispatch, playbackDispatch]);

  return null; // No UI rendered by this hook
};
