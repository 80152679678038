// Playback status types
export enum PlaybackStatus {
  Playing = 'playing',
  Paused = 'paused',
  Buffering = 'buffering',
}

// Playback state structure
export interface PlaybackState {
  timestamps: number[]; // Array of received timestamps
  playbackStatus: PlaybackStatus; // Current playback status
  currentTimestamp: number; // Currently playing timestamp
  minTimestamp: number; // Minimum timestamp
  maxTimestamp: number; // Maximum timestamp
  maxPlayableTimestamp: number; // Maximum timestamp that can be played
  isLive: boolean; // Is the playback in live mode
}

// Action types
export enum PlaybackActionType {
  ADD_TIMESTAMP = 'ADD_TIMESTAMP',
  SET_PLAYBACK_STATUS = 'SET_PLAYBACK_STATUS',
  SET_CURRENT_TIMESTAMP = 'SET_CURRENT_TIMESTAMP',
  ADVANCE_TIMESTAMP = 'ADVANCE_TIMESTAMP',
  SET_IS_LIVE = 'SET_IS_LIVE',
  PRUNE_OLD_TIMESTAMPS = 'PRUNE_OLD_TIMESTAMPS',
}

export type PlaybackAction =
  | { type: PlaybackActionType.ADD_TIMESTAMP; payload: number }
  | { type: PlaybackActionType.SET_PLAYBACK_STATUS; payload: PlaybackStatus }
  | { type: PlaybackActionType.SET_CURRENT_TIMESTAMP; payload: number | null }
  | { type: PlaybackActionType.ADVANCE_TIMESTAMP }
  | { type: PlaybackActionType.SET_IS_LIVE; payload: boolean }
  | { type: PlaybackActionType.PRUNE_OLD_TIMESTAMPS; payload: number };
