import turfDestination from '@turf/destination';
import { point as turfPoint } from '@turf/helpers';
import { useLoadMapImage } from 'src/@realtime/hooks';
import { useEffect } from 'react';
import { SPRITE_URL_PREFIX, SPRITES, SpriteOperationType } from './sprites';

/**
 * Queries features rendered on the map at a specific point and filters them by layer ID.
 *
 * This function allows you to get a list of features that are rendered at a given screen point
 * (e.g., where the user clicks or hovers), and filters them by a specified layer ID. It can be
 * used for interactions like identifying features in a specific layer, such as on-click or hover events.
 *
 * @param {mapboxgl.Map} target - The Mapbox GL JS map instance on which to query rendered features.
 * @param {mapboxgl.Point} point - The screen point (in pixels) at which to query features.
 * @param {string} layerId - The ID of the layer to filter features by.
 *
 * @returns {mapboxgl.MapboxGeoJSONFeature[]} - An array of Mapbox GeoJSON features that belong
 * to the specified layer and are rendered at the given point. If no matching features are found,
 * an empty array is returned.
 */
export const queryMapFeaturesById = (
  target: mapboxgl.Map,
  point: mapboxgl.Point,
  layerId: string
): mapboxgl.MapboxGeoJSONFeature[] => {
  // Query features rendered at the specified point on the map
  const features = target.queryRenderedFeatures(point);

  // If features are found, filter them by the given layer ID
  if (features) {
    return features.filter(({ layer }) => layer.id === layerId);
  }

  // Return an empty array if no features are found
  return [];
};

/**
 * Queries features rendered on the map at a specific point and filters them by layer IDs.
 *
 * This function allows you to get a list of features that are rendered at a given screen point
 * (e.g., where the user clicks or hovers), and filters them by a specified layer ID. It can be
 * used for interactions like identifying features in a specific layer, such as on-click or hover events.
 *
 * @param {mapboxgl.Map} target - The Mapbox GL JS map instance on which to query rendered features.
 * @param {mapboxgl.Point} point - The screen point (in pixels) at which to query features.
 * @param {string[]} layerId - The ID of the layer to filter features by.
 *
 * @returns {mapboxgl.MapboxGeoJSONFeature[]} - An array of Mapbox GeoJSON features that belong
 * to the specified layer and are rendered at the given point. If no matching features are found,
 * an empty array is returned.
 */
export const queryMapFeaturesByIds = (
  target: mapboxgl.Map,
  point: mapboxgl.Point,
  layerId: string[]
): mapboxgl.MapboxGeoJSONFeature[] => {
  // Query features rendered at the specified point on the map
  const features = target.queryRenderedFeatures(point);

  // If features are found, filter them by the given layer ID
  if (features) {
    return features.filter(({ layer }) => layerId.includes(layer.id));
  }

  // Return an empty array if no features are found
  return [];
};

/**
 * Calculates max bounds around a given latitude and longitude.
 *
 * @param latitude - The latitude of the center point.
 * @param longitude - The longitude of the center point.
 * @param distance - The distance in meters for the bounding box edges.
 * @returns The bounding box in the LngLatBoundsLike format.
 */
export const calculateMaxBounds = (
  latitude: number,
  longitude: number,
  distance: number
): mapboxgl.LngLatBoundsLike => {
  const centerPoint = turfPoint([longitude, latitude]);

  // Calculate the southwest and northeast corners
  const southWest = turfDestination(centerPoint, distance, 225).geometry.coordinates;
  const northEast = turfDestination(centerPoint, distance, 45).geometry.coordinates;

  return [southWest, northEast] as mapboxgl.LngLatBoundsLike;
};

export const loadMapSprites = (trackTheme = 'default') => {
  const loadMapImages = useLoadMapImage();

  useEffect(() => {
    const spriteList = [];

    Object.entries(SPRITES).flatMap(([sKey, sValue]) => {
      spriteList.push({
        url: `${SPRITE_URL_PREFIX}/shadow/${sValue}`,
        key: `shadow-${sKey}`,
      });
      Object.entries(SpriteOperationType).flatMap(([oKey, oValue]) => {
        spriteList.push({
          url: `${SPRITE_URL_PREFIX}/${trackTheme}/${oValue}-${sValue}`,
          key: `${oValue}-${sKey}`,
        });
      });
    });
    void loadMapImages(spriteList);
  }, [loadMapImages]);
};
