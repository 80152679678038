import React from 'react';
import { PopupProps } from 'react-map-gl';
import { FlightPopup } from './styles';

export const RealtimeMapTag: React.FC<PopupProps> = ({ children, offset, ...rest }) => {
  const offsetMap = {
    'top-left': [10, 10],
    'top-right': [-10, 10],
    'bottom-left': [10, -10],
    'bottom-right': [-10, -10],
    left: [10, 0],
    right: [-10, 0],
  };
  return (
    <FlightPopup
      offset={offset || (offsetMap[rest.anchor] as typeof offset)}
      className={`tooltip-${rest.anchor}`}
      {...rest}>
      {children}
    </FlightPopup>
  );
};
