import * as signalR from '@microsoft/signalr';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useSignalrConnection = ({
  url,
  token,
  onReconnected,
  onReconnecting,
  onClose,
  onStartError,
}: {
  url: string;
  token?: string;
  onReconnected?: (connectionId?: string) => void;
  onReconnecting?: () => void;
  onClose?: (error?: Error) => void;
  onStartError?: (error: unknown) => void;
}) => {
  const [connectionState, setConnectionState] = useState<signalR.HubConnectionState | null>(null);
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);

  const createConnection = useMemo(
    () =>
      new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token || '',
        })
        .withAutomaticReconnect()
        .build(),
    [url]
  );

  const startConnection = useCallback(async () => {
    try {
      if (connectionState === signalR.HubConnectionState.Connected) {
        return;
      }
      createConnection.onclose(() => onClose?.());
      createConnection.onreconnected(() => onReconnected?.());
      createConnection.onreconnecting(() => onReconnecting?.());
      await createConnection.start();

      setConnectionState(createConnection.state);
      setConnection(createConnection);
    } catch (error) {
      if (onStartError) {
        onStartError(error);
      }
      console.error('Error starting SignalR connection: ', error);
    }
  }, [connectionState, createConnection]);

  useEffect(() => {
    startConnection().catch(error => console.error('Error in useEffect startConnection: ', error));

    return () => {
      connection
        ?.stop()
        .catch(error => console.error('Error stopping SignalR connection: ', error));
    };
  }, [startConnection, connection]);

  return { connection, connectionState };
};
