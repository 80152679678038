import { useEffect, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useLoadLiveFlightData } from 'src/@realtime/hooks';
import { StreamedFlightPoint } from 'src/@realtime/contexts';
import { LOADING_BATCH_TIME } from 'src/@realtime/constants';

export const useBatchUpdates = () => {
  const queue = useRef<Record<string, StreamedFlightPoint[]>>({});
  const loadPointData = useLoadLiveFlightData();
  const dispatchInterval = useRef(0);

  useEffect(() => {
    dispatchInterval.current = window.setInterval(() => {
      if (!Object.keys(queue.current).length) {
        return;
      }

      unstable_batchedUpdates(() => {
        Object.entries(queue.current).forEach(([key, value]) => {
          value.forEach(point => {
            loadPointData(point);
          });
        });
        queue.current = {}; // Clear queue after processing
      });
    }, LOADING_BATCH_TIME);

    return () => {
      window.clearInterval(dispatchInterval.current);
    };
  }, []);

  return queue;
};
