import { useMemo, useRef, useCallback } from 'react';
import { useFlightDataContext, usePlaybackContext } from 'src/@realtime/contexts';
import { point, featureCollection, Feature, Point } from '@turf/turf';
import { SpriteKeys } from 'src/@realtime/utils/sprites';
import { SHADOW_ICON_OFFSETS } from 'src/@realtime/constants';

interface FlightProperties {
  trackId: string;
  iconKey: SpriteKeys;
  shadowOffset: [number, number];
  heading: number;
  operationType: string;
  operationIconKey: string;
  altitude: number;
}

interface TrackPosition {
  longitude: number;
  latitude: number;
  altitude: number;
  heading: number;
  time: number;
}

export const useBuildFlightData = () => {
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();
  const {
    state: { positions, flightInfo, lastFlightPruneTime },
  } = useFlightDataContext();

  // Cache heading calculations
  const headingCache = useRef(new Map<string, number>());

  // Cache feature collection to prevent unnecessary updates
  const previousFeatures = useRef<Feature<Point, FlightProperties>[]>([]);
  const previousTimestamp = useRef<number>(0);
  const previousPositionsRef = useRef(positions);

  // Memoize shadow offset calculations
  const shadowOffsetCache = useMemo(() => {
    const cache = new Map<number, [number, number]>();
    for (let i = 0; i < 360; i += 15) {
      cache.set(i, SHADOW_ICON_OFFSETS[i] || [0, 0]);
    }
    return cache;
  }, []);

  // Optimized binary search
  const findPosition = useCallback((trackPositions: TrackPosition[], timestamp: number): TrackPosition | null => {
    const len = trackPositions.length;
    if (len === 0 || timestamp < trackPositions[0].time || timestamp > trackPositions[len - 1].time) {
      return null;
    }

    // Try direct index based on time proportion
    const startTime = trackPositions[0].time;
    const timeRange = trackPositions[len - 1].time - startTime;
    const estimatedIndex = Math.floor((timestamp - startTime) / timeRange * (len - 1));
    if (trackPositions[estimatedIndex].time === timestamp) {
      return trackPositions[estimatedIndex];
    }
    // Quick linear search for nearby positions if close
    for (let i = Math.max(0, estimatedIndex - 2); i < Math.min(len, estimatedIndex + 3); i++) {
      if (trackPositions[i].time === timestamp) {
        return trackPositions[i];
      }
    }

    let left = 0;
    let right = len - 1;

    while (left <= right) {
      const mid = (left + right) >>> 1; // Unsigned right shift operator (>>>) to perform an integer division by 2, quicker than Math.Floor.
      const midTime = trackPositions[mid].time;

      if (midTime === timestamp) {
        return trackPositions[mid];
      }
      if (midTime < timestamp) {
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }
    return null;
  }, []);

  return useMemo(() => {
    // Skip update if nothing has changed
    if (
      currentTimestamp === previousTimestamp.current &&
      positions === previousPositionsRef.current &&
      previousFeatures.current.length > 0
    ) {
      return featureCollection(previousFeatures.current);
    }

    const features: Feature<Point, FlightProperties>[] = [];
    const tracksToProcess = Object.entries(positions);

    // Process in smaller chunks to maintain responsiveness
    const chunkSize = 50;
    let currentIndex = 0;

    while (currentIndex < tracksToProcess.length) {
      const endIndex = Math.min(currentIndex + chunkSize, tracksToProcess.length);
      const chunk = tracksToProcess.slice(currentIndex, endIndex);

      for (const [trackId, trackPositions] of chunk) {
        if (trackPositions.length < 2) { continue; }

        const pos = findPosition(trackPositions, currentTimestamp);
        if (!pos) { continue; }

        const { longitude, latitude, altitude, heading } = pos;
        const flightData = flightInfo[trackId];
        if (!flightData) { continue; }

        const { operationType, aircraftType } = flightData;

        // Use cached heading or calculate new one
        let roundedHeading = headingCache.current.get(trackId);
        if (roundedHeading === undefined || Math.abs(heading - roundedHeading) > 15) {
          roundedHeading = Math.round(heading / 15) * 15;
          headingCache.current.set(trackId, roundedHeading);
        }

        features.push(
          point<FlightProperties>(
            [longitude, latitude, altitude],
            {
              trackId,
              iconKey: aircraftType as SpriteKeys,
              shadowOffset: shadowOffsetCache.get(roundedHeading) || [0, 0],
              heading: roundedHeading,
              operationType,
              operationIconKey: operationType.toLowerCase(),
              altitude,
            },
            { id: trackId }
          )
        );
      }

      currentIndex = endIndex;
    }

    // Clean up heading cache
    const activeTrackIds = new Set(tracksToProcess.map(([id]) => id));
    for (const trackId of Array.from(headingCache.current.keys())) {
      if (!activeTrackIds.has(trackId)) {
        headingCache.current.delete(trackId);
      }
    }

    previousFeatures.current = features;
    previousTimestamp.current = currentTimestamp;
    previousPositionsRef.current = positions;

    return featureCollection(features);
  }, [
    currentTimestamp,
    positions,
    flightInfo,
    lastFlightPruneTime,
    findPosition,
    shadowOffsetCache,
  ]);
};