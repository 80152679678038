import React, { useMemo, useState, useEffect } from 'react';
import { useMap, Source, Layer } from 'react-map-gl';

// Components
import { ToolTipSelectionLayer } from '../flightSelectionLayer';

// Contexts
import { MapEventTypes } from 'src/@realtime/contexts/map';

// Hooks
import { useBuildFlightTails } from './hooks';
import { useAddMapEventFunction } from 'src/@realtime/hooks';

// Functions
import { queryMapFeaturesByIds } from 'src/@realtime/utils';
import { getAltitudeColor } from './utils';

// Constants
import { selectedTailStyle, defaultTailStyle, selectedHiddenTailStyle } from './styles';
import { RealtimeLayerIds } from 'src/@realtime/constants';

// Types
import { TailLineStringProperties } from 'src/@realtime/types/flight';
import { findNearestPoint } from 'src/@realtime/utils';

import { Feature, LineString, GeoJsonProperties } from 'geojson';

export const FlightTailLayer: React.FC = () => {
  const flightTailsData = useBuildFlightTails();
  if (!flightTailsData) {
    return null;
  }

  const [flightTails, selectedFlightTails] = flightTailsData;
  const [tooltipData, setTooltipData] = useState<{
    coordinates: GeoJSON.Position;
    altitude: number;
    trackId: string;
  }>({
    coordinates: [153.12915802001953, -27.343866804104714],
    altitude: 1048.079250909091,
    trackId: '10856',
  });

  const hoverAircraftFunction = useMemo(
    () => ({
      id: 'my-map-aircraft-hover',
      type: MapEventTypes.HOVER,
      event: (event: mapboxgl.MapLayerMouseEvent) => {
        const { target, point } = event;
        const features = queryMapFeaturesByIds(target, point, [
          RealtimeLayerIds.flightLayer,
          RealtimeLayerIds.selectedHiddenTail,
        ]); // Add a layer for aircraft
        if (features[0]) {
          if (features[0].properties) {
            if (features[0].geometry.type === 'Point') {
              const feature = (features[0] as unknown) as GeoJSON.Feature<
                GeoJSON.Point,
                {
                  altitude: number;
                  trackId: string;
                }
              >;
              setTooltipData({
                coordinates: feature.geometry.coordinates,
                altitude: feature.properties.altitude,
                trackId: feature.properties.trackId,
              });
            } else if (features[0].geometry.type === 'LineString') {
              const feature = (features[0] as unknown) as GeoJSON.Feature<
                GeoJSON.LineString,
                TailLineStringProperties
              >;
              const pointPosition = findNearestPoint(event, feature);
              setTooltipData({
                coordinates: pointPosition.coordinates,
                altitude: features[0].properties.altitude as number,
                trackId: feature.properties.trackId,
              });
            }
          }
        } else {
          setTooltipData(null);
        }
      },
    }),
    []
  );

  const { current: map } = useMap();
  const [zoom, setZoom] = useState<number | null>(null);

  useEffect(() => {
    if (!map) { return; }

    const handleZoom = () => {
      setZoom(map.getZoom());
    };

    map.on('zoom', handleZoom);
    map.getCanvas().style.cursor = tooltipData ? 'pointer' : 'default';

    return () => {
      map.off('zoom', handleZoom);
      map.getCanvas().style.cursor = 'default'; // Reset cursor on cleanup
    };
  }, [map, tooltipData]); // Dependencies

  useAddMapEventFunction(hoverAircraftFunction);

  const selectedFlightTailsWithColors = {
    ...selectedFlightTails,
    features: selectedFlightTails.features.flatMap(feature => {
      if (feature.geometry.type !== 'LineString') {
        return [];
      }

      const coordinates = (feature.geometry).coordinates;
      // Unlike the feature.geometry Coordinates LineString doesn't support Altitude,
      // so we store Altitudes in properties and here add one to each segment
      const altitudes: number[] = JSON.parse(feature.properties.altitudes) as number[]; // Convert stored JSON string to array as MapBox stringify's it.

      const segments: Feature<LineString, GeoJsonProperties>[] = [];

      for (let i = 0; i < coordinates.length - 1; i++) {
        // Needed to fix this bug: https://github.com/mapbox/mapbox-gl-js/issues/5171#issuecomment-333835891
        let precisionByZoom;
        switch (true) {
          case zoom < 9.9:
            precisionByZoom = 3;
            break;
          case zoom < 10.4:
            precisionByZoom = 4;
            break;
          default: // zoom >= 10.4
            precisionByZoom = 5;
            break;
        }
        const roundCoords = (coord: [number, number]) => [
          parseFloat(coord[0].toFixed(precisionByZoom)),
          parseFloat(coord[1].toFixed(precisionByZoom)),
        ];

        const altitude = altitudes[i] || 0;
        const color = getAltitudeColor(altitude);

        segments.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              roundCoords(coordinates[i] as [number, number]),
              roundCoords(coordinates[i + 1] as [number, number]),
            ], // Apply rounding
          },
          properties: {
            color,
            altitude,
            acId: feature.properties.acId as string,
            aircraftType: feature.properties.aircraftType as string,
            trackId: feature.properties.trackId as string,
            operationType: feature.properties.operationType as string,
            operationColor: feature.properties.operationColor as string,
            origin: feature.properties.origin as string,
          },
        });
      }
      return segments;
    }),
  };


  return (
    <>
      <Source id={RealtimeLayerIds.defaultTail} type="geojson" data={flightTails}>
        <Layer {...defaultTailStyle} />
      </Source>
      <Source
        id={RealtimeLayerIds.selectedTail}
        type="geojson"
        data={selectedFlightTailsWithColors}>
        <Layer {...selectedTailStyle(false)} />
        <Layer {...selectedHiddenTailStyle} />
      </Source>
      {tooltipData && <ToolTipSelectionLayer {...tooltipData} />}
    </>
  );
};
