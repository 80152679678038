import { CircleLayer } from 'react-map-gl';
import { RealtimeLayerIds } from 'src/@realtime/constants';
import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

export const activeNmtLayerStyle: CircleLayer = {
  id: RealtimeLayerIds.nmtActive,
  type: 'circle',
  paint: {
    'circle-color': [
      'step',
      ['get', 'noiseLevel'],
      sassVars['noisedb-30-35'],
      30,
      sassVars['noisedb-30-35'],
      35,
      sassVars['noisedb-35-40'],
      40,
      sassVars['noisedb-40-45'],
      45,
      sassVars['noisedb-45-50'],
      50,
      sassVars['noisedb-50-55'],
      55,
      sassVars['noisedb-55-60'],
      60,
      sassVars['noisedb-60-65'],
      65,
      sassVars['noisedb-65-70'],
      70,
      sassVars['noisedb-70-75'],
      75,
      sassVars['noisedb-75-80'],
      80,
      sassVars['noisedb-80-plus'],
    ],
    'circle-radius': [
      'case',
      ['>', ['get', 'noiseLevel'], 0], // When noiseLevel > 0
      4,
      3, // Default radius when noiseLevel is 0
    ],
  },
};

export const NmtTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
`;
