import { produce } from 'immer';
import {
  PLAYBACK_BUFFER_SIZE,
  PLAYBACK_HISTORY_MILLISECONDS,
  PLAYBACK_TICK_INTERVAL_MS,
} from 'src/@realtime/constants';
import { PlaybackAction, PlaybackActionType, PlaybackState, PlaybackStatus } from './types';

export const playbackReducer = produce((draft: PlaybackState, action: PlaybackAction) => {
  switch (action.type) {
    case PlaybackActionType.ADD_TIMESTAMP: {
      const newTimestamp = action.payload;

      if (draft.timestamps.length === 0) {
        draft.minTimestamp = newTimestamp + PLAYBACK_BUFFER_SIZE;
        draft.maxTimestamp = newTimestamp + PLAYBACK_HISTORY_MILLISECONDS;
        draft.timestamps.push(newTimestamp);
        draft.maxPlayableTimestamp = newTimestamp;
      } else {
        const lastTimestamp = draft.timestamps[draft.timestamps.length - 1];

        // Skip if it's a duplicate or out of order
        if (newTimestamp > lastTimestamp) {
          draft.timestamps.push(newTimestamp);
          draft.maxPlayableTimestamp = newTimestamp - PLAYBACK_BUFFER_SIZE;

          if (newTimestamp + PLAYBACK_BUFFER_SIZE * 2 > draft.maxTimestamp) {
            draft.maxTimestamp = newTimestamp + PLAYBACK_BUFFER_SIZE;
          }
        }
      }
      break;
    }

    case PlaybackActionType.SET_PLAYBACK_STATUS:
      draft.playbackStatus = action.payload;
      break;

    case PlaybackActionType.SET_CURRENT_TIMESTAMP:
      draft.currentTimestamp = action.payload;
      break;

    case PlaybackActionType.SET_IS_LIVE:
      draft.isLive = action.payload;
      break;

    case PlaybackActionType.ADVANCE_TIMESTAMP: {
      const nextTimestamp = draft.currentTimestamp + PLAYBACK_TICK_INTERVAL_MS;

      if (nextTimestamp > draft.maxTimestamp) {
        draft.playbackStatus = PlaybackStatus.Buffering;
      } else {
        draft.currentTimestamp = nextTimestamp;
      }
      break;
    }

    case PlaybackActionType.PRUNE_OLD_TIMESTAMPS: {
      const cutoffTimestamp = action.payload;
      draft.timestamps = draft.timestamps.filter(ts => ts >= cutoffTimestamp);
      draft.minTimestamp = draft.timestamps.length > 0 ? draft.timestamps[0] : cutoffTimestamp;
      break;
    }

    default:
      break;
  }
});
