import React, { useCallback } from 'react';
import { ControlPosition, useControl } from 'react-map-gl';


interface MapControlButtonProps {
  title: string;
  onClick: () => void;
  iconName: string;
  className?: string;
  position?: ControlPosition;
}
class CustomControl {
  private container: HTMLDivElement;

  constructor(onClick: () => void, title: string, iconName: string, className: string) {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    const button = document.createElement('button');
    button.type = 'button';
    button.ariaLabel = title;
    button.title = title;
    button.className = className;
    button.onclick = onClick;

    // Add SVG manually for the icon
    const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    icon.setAttribute('height', '20');
    icon.setAttribute('width', '20');
    icon.setAttribute('class', 'mosaic-icon');

    const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${iconName}`);
    icon.appendChild(use);

    button.appendChild(icon);
    this.container.appendChild(button);
  }

  onAdd() {
    return this.container;
  }

  onRemove() {
    this.container.remove();
  }
}

export const MapControlButton: React.FC<MapControlButtonProps> = ({
  title,
  onClick,
  iconName,
  className = '',
  position = 'top-right',
}) => {
  const createControl = useCallback(() => new CustomControl(onClick, title, iconName, className), [
    onClick,
    title,
    iconName,
    className,
  ]);
  //console.log('createControl', title);
  useControl(createControl, { position });

  return null;
};