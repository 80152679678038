import { NmtState, NmtAction, NmtActionType } from './types';
import { StreamedNoiseSample } from "src/@realtime/types/realtime";

export const nmtReducer = (state: NmtState, action: NmtAction): NmtState => {
  switch (action.type) {
    case NmtActionType.ADD_MONITORS:
      return {
        ...state,
        hasLoaded: true,
        monitors: [...state.monitors, ...action.payload],
      };
    case NmtActionType.REMOVE_MONITORS:
      return {
        ...state,
        monitors: state.monitors.filter(monitor => !action.payload.includes(monitor)),
      };
    case NmtActionType.ADD_NOISE_SAMPLE: {
      const samples = action.payload; // payload is an array of StreamedNoiseSample
      const deviceLocationId = samples[0]?.deviceLocationId; // Get the deviceLocationId from the first sample

      if (!deviceLocationId) {
        console.warn('No deviceLocationId found in noise samples. Skipping update.');
        return state;
      }

      const updatedSamples = {
        ...state.samples,
        [deviceLocationId]: [
          ...(state.samples[deviceLocationId] || []),
          ...samples,
        ],
      };

      return {
        ...state,
        samples: updatedSamples,
      };
    }
    case NmtActionType.PRUNE_OLD_NOISE_DATA: {
      const pruneThresholdTime = action.payload;
      const prunedSamples = Object.fromEntries(
        Object.entries(state.samples)
          .map(([deviceLocationId, sampleList]) => {

            // Track retained samples and last removed timestamp
            const filteredSamples: StreamedNoiseSample[] = [];
            let lastRemovedTime: number | null = null;

            for (const sample of sampleList) {
              const sampleTime = new Date(sample.time).getTime();

              if (sampleTime >= pruneThresholdTime) {
                filteredSamples.push(sample);
              } else {
                lastRemovedTime = lastRemovedTime !== null ? Math.max(lastRemovedTime, sampleTime) : sampleTime;
              }
            }

            return filteredSamples.length > 0 ? [deviceLocationId, filteredSamples] : null;
          })
          .filter(Boolean)
      );

      return {
        ...state,
        samples: prunedSamples,
        lastNoisePruneTime: Date.now(),
      };
    }
    default:
      return state;
  }
};
