import styled from 'styled-components';
import { Popup, PopupProps } from 'react-map-gl';

export const FlightPopup = styled(Popup)<PopupProps>`
  .mapboxgl-popup-content {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    color: #fff;
    padding: 2px 4px;
    border-radius: 0;
  }

  /* Bottom-right arrow */
  &.tooltip-bottom-right .mapboxgl-popup-content::after {
    bottom: -10px; /* Position below the tooltip */
    right: 10px; /* Align to the right */
    border-color: rgba(0, 0, 0, 0.4) transparent transparent transparent;
  }

  /* Bottom-left arrow */
  &.tooltip-bottom-left .mapboxgl-popup-content::after {
    bottom: -10px;
    left: 10px;
    border-color: rgba(0, 0, 0, 0.4) transparent transparent transparent;
  }

  /* Top-right arrow */
  &.tooltip-top-right .mapboxgl-popup-content::after {
    top: -10px; /* Position above the tooltip */
    right: 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.4) transparent;
  }

  /* Top-left arrow */
  &.tooltip-top-left .mapboxgl-popup-content::after {
    top: -10px;
    left: 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.4) transparent;
  }

  .mapboxgl-popup-tip {
    display: none; /* Hides the default arrow */
  }

  .mapboxgl-popup-close-button {
    display: none; /* Hides the default arrow */
  }
`;
