import { RealtimeConnectionType, useAuthStoreContext } from 'src/@realtime/contexts';
import { useConfigSelectors } from 'src/app/reducers';
import { getDeployedProductId } from 'src/utils';

import { failedToast, reconnectToast, successToast } from './styles';

/**
 * Creates a SignalR configuration object for a given connection type and stream name.
 *
 * @param {RealtimeConnectionType} type - The type of the realtime connection.
 * @param {string} streamName - The name of the stream to connect to.
 * @returns {object} The configuration object for the SignalR connection.
 * @property {string} url - The URL for the SignalR connection.
 * @property {string} token - The authentication token for the connection.
 * @property {function} onReconnected - Callback function to be called when the connection is successfully reconnected.
 * @property {function} onReconnecting - Callback function to be called when the connection is attempting to reconnect.
 * @property {function} onClose - Callback function to be called when the connection is closed or failed.
 */
export const createSignalrConfig = (
  type: RealtimeConnectionType,
  streamName: string
): {
  url: string;
  token: string;
  onReconnected: () => void;
  onReconnecting: () => void;
  onClose: () => void;
} => {
  const { token } = useAuthStoreContext();
  const {
    AviationRealtimeApi: { EndpointUrl },
  } = useConfigSelectors().getConfig();
  return {
    url: `${EndpointUrl}${getDeployedProductId()}/${streamName}`,
    token,
    onReconnected: () => {
      console.log(`${type} succesfully reconnected`);
      successToast(type);
    },
    onReconnecting: () => {
      console.warn(`${type} attempting to reconnect`);
      reconnectToast(type);
    },
    onClose: () => {
      console.warn(`${type} connection failed`);
      failedToast(type);
    },
  };
};
