import React, { useEffect, useState, useCallback } from 'react';
import { Layer, useMap } from 'react-map-gl';
import { RealtimeLayerIds } from 'src/@realtime/constants';

export const ShadedMapLayer: React.FC = () => {
  const { current: map } = useMap();
  const [beforeId, setBeforeId] = useState<string | null>(null);

  const findLowestLayer = useCallback(() => {
    if (!map) {
      return;
    }

    const style = map.getStyle();
    if (!style?.layers) {
      return;
    }

    const mapLayers = new Set(style.layers.map(layer => layer.id));
    const layerIds = Object.values(RealtimeLayerIds).filter(id => mapLayers.has(id));

    if (layerIds.length > 0) {
      setBeforeId(layerIds[layerIds.length - 1]);
    }
  }, [map]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (map.isStyleLoaded()) {
      findLowestLayer();
    } else {
      map.once('styledata', findLowestLayer);
    }

    map.on('styledata', findLowestLayer);
    map.on('addlayer', findLowestLayer);

    return () => {
      map.off('styledata', findLowestLayer);
      map.off('addlayer', findLowestLayer);
    };
  }, [map, findLowestLayer]);

  return (
    <Layer
      id="dark-grey-overlay"
      type="background"
      paint={{
        'background-color': 'rgba(0, 0, 0, .52)',
      }}
      beforeId={beforeId} // Insert before the first found layer
    />
  );
};
