import { SpriteKeys } from 'src/@realtime/utils/sprites';
import { FlightDataAction, FlightDataActionType, FlightDataState } from './types';
import { interpolateMissingPoints } from 'src/@realtime/utils';
import { FlightPosition } from 'src/@realtime/contexts/flight/types';
import { PLAYBACK_HISTORY_MILLISECONDS } from 'src/@realtime/constants';

const getRandomSpriteKey = (): SpriteKeys => {
  const values = Object.values(SpriteKeys); // Get an array of enum values
  const randomIndex = Math.floor(Math.random() * values.length); // Generate a random index
  return values[randomIndex]; // Return the value at the random index
};

export const flightReducer = (
  state: FlightDataState,
  action: FlightDataAction
): FlightDataState => {
  switch (action.type) {
    case FlightDataActionType.ADD_FLIGHT: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        operationType,
        operationColor,
        point: { trackId, time, latitude, longitude, altitude, ...staticData },
      } = action.payload;

      // Only add static info if this trackId is new
      if (!state.flightInfo[trackId]) {
        return {
          ...state,
          flightInfo: {
            ...state.flightInfo,
            [trackId]: {
              ...staticData,
              operationType,
              operationColor,
              aircraftType: getRandomSpriteKey(),
            },
          },
        };
      }
      return state;
    }
    case FlightDataActionType.ADD_POSITION: {
      const { trackPoint, time } = action.payload;
      const { trackId } = trackPoint;
      const { positions } = state;

      const now = Date.now();
      const pruneThresholdTime = now - PLAYBACK_HISTORY_MILLISECONDS;
      if (time < pruneThresholdTime) {
        // console.log(`Skipping track ${trackId}, it was pruned`);
        return { ...state };
      }

      // Check if the trackId exists
      if (!positions[trackId]) {
        return {
          ...state,
          positions: {
            ...state.positions,
            [trackPoint.trackId]: [
              {
                time,
                latitude: trackPoint.latitude,
                longitude: trackPoint.longitude,
                altitude: trackPoint.altitude,
                heading: 0,
              },
            ],
          },
        };
      }

      // Check if the position already exists
      if (positions[trackPoint.trackId].some(position => position.time === time)) {
        return state;
      } else {
        let previousPoint: FlightPosition | undefined = undefined;
        for (let i = positions[trackId].length - 1; i >= 0; i--) {
          if (positions[trackId][i].time < time) {
            previousPoint = positions[trackId][i];
            break;
          }
        }

        if (previousPoint) {
          const pointsToDispatch = interpolateMissingPoints(previousPoint, {
            time,
            latitude: trackPoint.latitude,
            longitude: trackPoint.longitude,
            altitude: trackPoint.altitude,
            heading: 0,
          });
          return {
            ...state,
            positions: {
              ...state.positions,
              [trackId]: [...state.positions[trackId], ...pointsToDispatch],
            },
          };
        }
      }

      return state;
    }
    case FlightDataActionType.ADD_POSITIONS: {
      const { trackId, positions } = action.payload;
      const positionsPayload = Array.isArray(positions) ? positions : [positions];

      const updatedPositions = { ...state.positions };

      // Add each position to the respective trackId
      for (const positionData of positionsPayload) {
        const { time, latitude, longitude, altitude, heading } = positionData;

        // Initialize the positions array if it doesn't exist
        if (!updatedPositions[trackId]) {
          updatedPositions[trackId] = [];
        }

        // Check if a point with the same timestamp already exists
        const existingIndex = updatedPositions[trackId].findIndex(pos => pos.time === time);

        // Append the new position data if it doesn't exist already
        if (existingIndex === -1) {
          updatedPositions[trackId].push({
            time: new Date(time).getTime(),
            latitude,
            longitude,
            altitude,
            heading,
          });
        }
      }

      return {
        ...state,
        positions: updatedPositions,
      };
    }
    case FlightDataActionType.PRUNE_OLD_FLIGHT_DATA: {
      const pruneThresholdTime = action.payload;

      // 🔥 First, remove old positions inside each track
      const filteredPositions = Object.fromEntries(
        Object.entries(state.positions).map(([trackId, trackPositions]) => [
          trackId,
          trackPositions.filter(position => position.time >= pruneThresholdTime), // ✅ Remove old positions
        ])
      );

      // 🔥 Then, remove tracks that have no remaining positions
      const prunedPositions = Object.fromEntries(
        Object.entries(filteredPositions).filter(([_, trackPositions]) => trackPositions.length > 0) // ✅ Remove empty tracks
      );

      // 🔥 Ensure flightInfo is also removed for pruned tracks
      const prunedFlightInfo = Object.fromEntries(
        Object.entries(state.flightInfo).filter(([trackId]) => prunedPositions[trackId]) // Keep only valid tracks
      );

      return {
        ...state,
        positions: { ...prunedPositions },
        flightInfo: { ...prunedFlightInfo },
        lastFlightPruneTime: Date.now(),
        selectedTracks: [...state.selectedTracks],
      };
    }
    case FlightDataActionType.SELECT_TRACK: {
      const trackId = action.payload;
      const { selectedTracks } = state;

      // Support for multiple tracks
      // const updatedSelectedTracks = selectedTracks.includes(trackId)
      //   ? selectedTracks.filter(id => id !== trackId)
      //   : [...selectedTracks, trackId];

      // Currently we only allow one track selected at a time
      const updatedSelectedTracks = selectedTracks.includes(trackId)
        ? [] // Deselect if already selected
        : [trackId]; // Replace with the new selection

      return {
        ...state,
        selectedTracks: updatedSelectedTracks,
      };
    }
    default:
      return state;
  }
};
