import React from 'react';
import { useMapConfigsWrapper } from 'src/@realtime/hooks';
import { Map, MapControls, ShadedMapLayer, RealtimeLayers } from 'src/@realtime/components';
import { calculateMaxBounds } from 'src/@realtime/utils';
import { DEFAULT_BBOX_DISTANCE } from 'src/constants';
import { useMapContext } from 'src/@realtime/contexts/map';

export const RealtimePage: React.FC = () => {
  const {
    centre: { latitude, longitude },
    zoom,
    mapAccessToken,
    maxZoom,
    minZoom,
  } = useMapConfigsWrapper();

  const {
    state: { isMapLoaded },
  } = useMapContext();

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Map
        pitch={0}
        bearing={0}
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        apiKey={mapAccessToken}
        maxZoom={maxZoom}
        minZoom={minZoom}
        maxBounds={calculateMaxBounds(latitude, longitude, DEFAULT_BBOX_DISTANCE)}>
        <MapControls resetView mapSettings />
        {isMapLoaded && <RealtimeLayers />}
        <ShadedMapLayer />
      </Map>
    </div>
  );
};
