import { useCallback } from 'react';
import { LOADING_BATCH_TIME } from 'src/@realtime/constants';
import { PlaybackActionType, PlaybackStatus, usePlaybackContext } from 'src/@realtime/contexts';

export const useGoLive = () => {
  const {
    state: { maxPlayableTimestamp, playbackStatus },
    dispatch,
  } = usePlaybackContext();

  const goLive = useCallback(() => {
    dispatch({
      type: PlaybackActionType.SET_CURRENT_TIMESTAMP,
      payload: maxPlayableTimestamp - LOADING_BATCH_TIME,
    });
    dispatch({
      type: PlaybackActionType.SET_IS_LIVE,
      payload: true,
    });
    if (playbackStatus !== PlaybackStatus.Playing) {
      dispatch({
        type: PlaybackActionType.SET_PLAYBACK_STATUS,
        payload: PlaybackStatus.Playing,
      });
    }
  }, [dispatch, maxPlayableTimestamp]);

  return goLive;
};
