import React, { useEffect } from 'react';
import {
  NmtActionType,
  RealtimeConnectionType,
  useNmtContext,
  useRealtimeConnectionContext,
} from 'src/@realtime/contexts';
import { usePruneData, useSignalrConnection, useSignalrSubscription } from 'src/@realtime/hooks';
import {
  PLAYBACK_MAX_HISTORY,
  STREAM_HUB_NAMES,
  STREAM_METHOD_NAMES,
} from 'src/@realtime/constants';
import {
  StreamedSubscriptionParameters,
  StreamedFlightPoints,
  StreamedNoiseSamples,
} from 'src/@realtime/types';
import { useBatchUpdates } from './hooks';
import { createSignalrConfig } from './utils';

export const RealtimeConnections: React.FC = () => {
  const queue = useBatchUpdates();
  const { dispatch: nmtDispatch } = useNmtContext();
  const { setConnection } = useRealtimeConnectionContext();

  const { connection: flightConnection } = useSignalrConnection(
    createSignalrConfig(RealtimeConnectionType.Flight, STREAM_HUB_NAMES.REAL_TIME_FLIGHT)
  );

  const { connection: noiseConnection } = useSignalrConnection(
    createSignalrConfig(RealtimeConnectionType.Noise, STREAM_HUB_NAMES.REAL_TIME_NOISE)
  );

  usePruneData();

  useEffect(() => {
    if (flightConnection) {
      setConnection(flightConnection, RealtimeConnectionType.Flight);
    }
    if (noiseConnection) {
      setConnection(noiseConnection, RealtimeConnectionType.Noise);
    }
  }, [flightConnection, noiseConnection]);

  useSignalrSubscription<StreamedFlightPoints, StreamedSubscriptionParameters>({
    connection: flightConnection || null,
    subscriptionParameters: flightConnection
      ? [
          {
            streamParameters: [
              STREAM_METHOD_NAMES.GET_TRACK_POINTS,
              { historySeconds: PLAYBACK_MAX_HISTORY },
            ],
            subscriber: {
              next: (data: StreamedFlightPoints) => {
                const { trackId } = data.point;

                if (!queue.current[trackId]) {
                  queue.current[trackId] = [data.point];
                } else {
                  queue.current[trackId].push(data.point);
                }
              },
              complete: () => {},
              error: e => console.log,
            },
          },
        ]
      : [],
  });

  useSignalrSubscription<StreamedNoiseSamples, StreamedSubscriptionParameters>({
    connection: noiseConnection || null,
    subscriptionParameters: noiseConnection
      ? [
          {
            streamParameters: [
              STREAM_METHOD_NAMES.GET_NOISE_SAMPLES,
              { historySeconds: PLAYBACK_MAX_HISTORY },
            ],
            subscriber: {
              next: (data: StreamedNoiseSamples) => {
                // TODO: Remove this logging diagnostics once AS-893 is resolved
                if (!data || typeof data !== 'object') {
                  console.error('AS-893: Invalid NoiseSample Data. Expected an object:', data);
                  alert(
                    'AS-893: Invalid NoiseSample Data. Expected an object. Check console for details.'
                  );
                  return;
                }

                if (!data.sample) {
                  console.error("AS-893: Missing 'sample' field in NoiseSample Data:", data);
                  alert(
                    'AS-893: Invalid NoiseSample Data. Expected an object. Check console for details.'
                  );
                  return;
                }

                nmtDispatch({
                  type: NmtActionType.ADD_NOISE_SAMPLE,
                  payload: [data.sample],
                });
              },
              complete: () => {},
              error: e => console.log,
            },
          },
        ]
      : [],
  });

  return null;
};
