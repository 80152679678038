import React, { useMemo, useState } from 'react';
import { Source, Layer } from 'react-map-gl';
import { RealtimeLayerIds, RealtimeMapEventIds } from 'src/@realtime/constants';
import { activeNmtLayerStyle, NmtTooltipContainer } from './styles';
import { useBuildActiveNmt } from './hooks';
import { MapEventTypes } from 'src/@realtime/contexts/map';
import { queryMapFeaturesByIds } from 'src/@realtime/utils';
import { useAddMapEventFunction } from 'src/@realtime/hooks';
import { NmtLayerProperties } from './types';
import { RealtimeMapTag } from 'src/@realtime/components';
import { useNmtContext, usePlaybackContext } from 'src/@realtime/contexts';

export const NmtTooltip: React.FC<{ coordinates: GeoJSON.Position; deviceId: number }> = ({
  coordinates,
  deviceId,
}) => {
  if (!coordinates) {
    return null;
  }
  const {
    state: { monitors, samples },
  } = useNmtContext();
  const {
    state: { currentTimestamp },
  } = usePlaybackContext();
  const monitor = monitors.find(monitor => monitor.deviceId === deviceId);
  const sample = samples[deviceId]?.find(
    ({ time }) => new Date(time).getTime() === currentTimestamp
  );
  return (
    <RealtimeMapTag longitude={coordinates[0]} latitude={coordinates[1]} anchor={'top-right'}>
      <NmtTooltipContainer>
        <span className="deviceName" style={{ fontWeight: 'bold', lineHeight: '1' }}>
          {monitor?.deviceName}
        </span>
        <span className="locationDescription">{monitor?.locationDescription}</span>
        {sample ? (
          <span className="noiseLevel">{sample.value.toFixed(2)} Db</span>
        ) : (
          <span className="noiseLevel">Inactive</span>
        )}
      </NmtTooltipContainer>
    </RealtimeMapTag>
  );
};

export const NmtLayer: React.FC = () => {
  const [tooltipData, setTooltipData] = useState<{
    coordinates: GeoJSON.Position;
    deviceId: number;
  } | null>(null);

  const nmtData = useBuildActiveNmt();
  const nmtHoverFunction = useMemo(
    () => ({
      id: RealtimeMapEventIds.nmtHover,
      type: MapEventTypes.HOVER,
      event: (event: mapboxgl.MapLayerMouseEvent) => {
        const { target, point } = event;
        const features = queryMapFeaturesByIds(target, point, [RealtimeLayerIds.nmtActive]);
        if (features[0]) {
          const feature = (features[0] as unknown) as GeoJSON.Feature<
            GeoJSON.Point,
            NmtLayerProperties
          >;
          setTooltipData({
            coordinates: feature.geometry.coordinates,
            deviceId: feature.properties.deviceId,
          });
        } else {
          setTooltipData(null);
        }
      },
    }),
    []
  );

  useAddMapEventFunction(nmtHoverFunction);

  return (
    <>
      <Source id={RealtimeLayerIds.nmtActive} type="geojson" data={nmtData}>
        <Layer {...activeNmtLayerStyle} />
      </Source>
      {NmtTooltip && <NmtTooltip {...tooltipData} />}
    </>
  );
};
