import { SymbolLayer } from 'react-map-gl';
import { RealtimeLayerIds } from 'src/@realtime/constants';

export const flightLayerStyle: SymbolLayer = {
  id: RealtimeLayerIds.flightLayer,
  type: 'symbol',
  layout: {
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': 1,
    'icon-image': ['concat', ['get', 'operationIconKey'], '-', ['get', 'iconKey']],
    'icon-rotate': ['get', 'heading'],
  },
};

export const shadowLayerStyle: SymbolLayer = {
  id: RealtimeLayerIds.flightShadowLayer,
  type: 'symbol',
  layout: {
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': ['get', 'shadowOffset'],
    'icon-size': 1,
    'icon-image': ['concat', 'shadow-', ['get', 'iconKey']],
    'icon-rotate': ['get', 'heading'],
  },
  paint: {
    'icon-opacity': 0.5,
  },
};
