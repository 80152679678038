// SignalR constants
export const STREAM_METHOD_NAMES = {
  GET_TRACK_POINTS: 'GetTrackPoints',
  GET_NOISE_SAMPLES: 'GetNoiseSamples',
};

export const STREAM_HUB_NAMES = {
  REAL_TIME_FLIGHT: 'realtimeflight',
  REAL_TIME_NOISE: 'realtimenoise',
};

// Playback constants
export const PLAYBACK_HISTORY_MINUTES = 10; // Playback history to keep in minutes
export const PLAYBACK_HISTORY_SECONDS = PLAYBACK_HISTORY_MINUTES * 60;
export const PLAYBACK_HISTORY_MILLISECONDS = PLAYBACK_HISTORY_SECONDS * 1000;
export const PLAYBACK_TICK_INTERVAL_MS = 1000; // Tick speed
export const PLAYBACK_MAX_HISTORY = 120; // History to load from endpoint in seconds
export const PLAYBACK_BUFFER_SIZE = PLAYBACK_TICK_INTERVAL_MS * 20; // Buffer size in ms
export const LOADING_BATCH_TIME = 5000; // Time to wait before loading next batch of data

// Map constants
export const MapStyles = {
  Basic: {
    url: 'mapbox://styles/mapbox/light-v11',
    name: 'Basic',
    img: '/map/mapstyle_basic.png',
  },
  Street: {
    url: 'mapbox://styles/mapbox/streets-v12',
    name: 'Street',
    img: '/map/mapstyle_street.png',
  },
  Satellite: {
    url: 'mapbox://styles/mapbox/satellite-v9',
    name: 'Satellite',
    img: '/map/mapstyle_satellite.png',
  },
};

// This list MUST be in order of preference, shadow layer uses last layer in order to apply itself
export enum RealtimeLayerIds {
  // Tooltip
  toolTipAircraft = 'tooltip-aircraft',
  // Flights
  flightLayer = 'flight-layer',
  flightShadowLayer = 'flight-shadow-layer',
  selectedAircraftCircle = 'selected-aircraft-pulsating-circle',
  // Tails
  defaultTail = 'default-tail',
  selectedTail = 'selected-tail',
  selectedHiddenTail = 'selected-hidden-tail', // This is used to help with event register
  // Noise
  nmtActive = 'nmt-active',
}

export enum RealtimeMapEventIds {
  nmtHover = 'nmt-hover',
}

export const SHADOW_ICON_OFFSETS: Record<number, [number, number]> = {
  0: [5.0, 5.0],
  15: [6.125, 3.535],
  30: [6.83, 1.83],
  45: [7.07, 0.0],
  60: [6.83, -1.83],
  75: [6.125, -3.535],
  90: [5.0, -5.0],
  105: [3.535, -6.125],
  120: [1.83, -6.83],
  135: [0.0, -7.07],
  150: [-1.83, -6.83],
  165: [-3.535, -6.125],
  180: [-5.0, -5.0],
  195: [-6.125, -3.535],
  210: [-6.83, -1.83],
  225: [-7.07, 0.0],
  240: [-6.83, 1.83],
  255: [-6.125, 3.535],
  270: [-5.0, 5.0],
  285: [-3.535, 6.125],
  300: [-1.83, 6.83],
  315: [0.0, 7.07],
  330: [1.83, 6.83],
  345: [3.535, 6.125],
  360: [5.0, 5.0],
};
