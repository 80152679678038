import { STREAM_METHOD_NAMES } from 'src/@realtime/constants';

export type StreamedFlightParameters = [
  typeof STREAM_METHOD_NAMES.GET_TRACK_POINTS,
  { historySeconds?: number }
];

export interface StreamedFlightPoints {
  point: StreamedFlightPoint;
}

// Data types for flight information
export interface StreamedFlightPoint {
  trackId: string;
  time: string;
  beaconCode: number;
  destination: string;
  acId: string;
  aircraftType: string;
  tailNumber: string;
  origin: string;
  latitude: number;
  longitude: number;
  altitude: number;
  operationType: OperationType;
}

export enum OperationType {
  Arrival = 'Arrival',
  Departure = 'Departure',
  Overflight = 'Overflight',
  TouchAndGo = 'TouchAndGo',
  Miscellaneous = 'Miscellaneous',
}

// Static information type (does not include changing values)
interface FlightInfo
  extends Omit<StreamedFlightPoint, 'latitude' | 'longitude' | 'altitude' | 'time' | 'trackId'> {
  operationType: OperationType;
  operationColor: string;
}

// Dynamic position information type
export interface FlightPosition {
  time: number;
  latitude: number;
  longitude: number;
  altitude: number;
  heading: number;
}

// State type
export interface FlightDataState {
  flightInfo: Record<string, FlightInfo>;
  positions: Record<string, FlightPosition[]>;
  selectedTracks: string[];
  lastFlightPruneTime: number;
}

// Action types
export enum FlightDataActionType {
  ADD_FLIGHT = 'ADD_FLIGHT',
  ADD_POSITIONS = 'ADD_POSITIONS',
  ADD_POSITION = 'ADD_POSITION',
  SELECT_TRACK = 'SELECT_TRACK',
  PRUNE_OLD_FLIGHT_DATA = 'PRUNE_OLD_FLIGHT_DATA',
}

export type FlightDataAction =
  | {
      type: FlightDataActionType.ADD_FLIGHT;
      payload: {
        point: StreamedFlightPoint;
        operationType: OperationType;
        operationColor: string;
      };
    }
  | {
      type: FlightDataActionType.ADD_POSITIONS;
      payload: {
        positions: FlightPosition | FlightPosition[];
        trackId: string;
      };
    }
  | {
      type: FlightDataActionType.ADD_POSITION;
      payload: {
        trackPoint: StreamedFlightPoint;
        time: number;
      };
    }
  | {
      type: FlightDataActionType.SELECT_TRACK;
      payload: string;
    }
  | {
      type: FlightDataActionType.PRUNE_OLD_FLIGHT_DATA;
      payload: number;
    };
