import React from 'react';

import { CircleButton } from './styles';
import { Spinner, Icons } from '@ems/client-design-system';

import { usePlaybackContext, PlaybackStatus, PlaybackActionType } from 'src/@realtime/contexts';

export const PlaybackButton: React.FC = ({ }) => {
  const {
    state: { playbackStatus },
    dispatch: playbackDispatch,
  } = usePlaybackContext();

  const handlePlaybackToggle = () => {
    if (playbackStatus === PlaybackStatus.Buffering) {
      return;
    }
    playbackDispatch({
      type: PlaybackActionType.SET_PLAYBACK_STATUS,
      payload:
        playbackStatus === PlaybackStatus.Playing ? PlaybackStatus.Paused : PlaybackStatus.Playing,
    });
    // When the user pauses we can't be in Live mode
    if (playbackStatus === PlaybackStatus.Playing) {
      playbackDispatch({
        type: PlaybackActionType.SET_IS_LIVE,
        payload: false,
      });
    }
  };
  return (
    <CircleButton onClick={handlePlaybackToggle}>
      {playbackStatus === PlaybackStatus.Buffering ? (
        <Spinner loading size="s" />
      ) : playbackStatus === PlaybackStatus.Playing ? (
        <Icons iconName={`ic-ui-pause`} size={'24px'} />
      ) : (
        <Icons iconName={`ic-ui-play`} size={'24px'} />
      )}
    </CircleButton>
  );
};
