// Rather than using a constant, we calculate the prune interval based on device memory
interface NavigatorExtended extends Navigator {
  deviceMemory?: number;
}

// Calculate the prune interval based on device memory,
// for devices with 2GB or less, the interval is 20 seconds,
// for devices with 8GB or more, the interval is 1 minute
// The makes the Garbage collection more aggressive on low-memory devices
const calculatePruneInterval = deviceMemory => {
  const MIN_INTERVAL = 20000; // Minimum interval for low-memory devices (20 seconds)
  const MAX_INTERVAL = 60000; // Maximum interval for high-memory devices (1 minute)

  // Clamp deviceMemory to a max of 8 to avoid large values skewing the interval
  const clampedMemory = Math.min(deviceMemory, 8);

  // Scale the interval linearly between MIN_INTERVAL and MAX_INTERVAL
  const interval = MIN_INTERVAL + ((clampedMemory - 2) / (8 - 2)) * (MAX_INTERVAL - MIN_INTERVAL);

  return Math.round(interval);
};

export const usePlaybackPruneInterval = () => {
  const navigatorExtended = navigator as NavigatorExtended;

  // This returns values: 0.25, 0.5, 1, 2, 4, 8. Browsers cap RAM at 8GB and this isn't precise
  const deviceMemory = navigatorExtended.deviceMemory || 1; // Default to 1GB if unsupported

  return calculatePruneInterval(deviceMemory);
};
