
/**
 * Auto-generated file. Do not edit manually.
 */
export const SPRITES = {
  "blimp": "blimp.png",
  "cargo": "cargo.png",
  "businessjet": "businessjet.png",
  "helicopter1": "helicopter1.png",
  "helicopter2": "helicopter2.png",
  "military": "military.png",
  "piston": "piston.png",
  "regionaljet": "regionaljet.png",
  "turboprop": "turboprop.png",
  "unknown": "unknown.png"
} as const;
export const SPRITE_URL_PREFIX = '/sprites/png';

export enum SpriteKeys {
    blimp = 'blimp',
    cargo = 'cargo',
    businessjet = 'businessjet',
    helicopter1 = 'helicopter1',
    helicopter2 = 'helicopter2',
    military = 'military',
    piston = 'piston',
    regionaljet = 'regionaljet',
    turboprop = 'turboprop',
    unknown = 'unknown'
}

export enum SpriteOperationType {
  touchandgo = 'touchandgo',
  arrival = 'arrival',
  departure = 'departure',
  overflight = 'overflight'
}

