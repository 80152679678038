import { NoiseMonitor } from 'src/@realtime/queries';
import { StreamedNoiseSample } from 'src/@realtime/types';

export interface NmtState {
  monitors: NoiseMonitor[];
  hasLoaded: boolean;
  samples: Record<string, StreamedNoiseSample[]>;
  lastNoisePruneTime: number;
}

export enum NmtActionType {
  ADD_MONITORS = 'ADD_MONITORS',
  REMOVE_MONITORS = 'REMOVE_MONITORS',
  ADD_NOISE_SAMPLE = 'ADD_NOISE_SAMPLE',
  PRUNE_OLD_NOISE_DATA = 'PRUNE_OLD_NOISE_DATA',
}

// Action types
export type NmtAction =
  | {
    type: NmtActionType.ADD_MONITORS;
    payload: NoiseMonitor[];
  }
  | {
    type: NmtActionType.REMOVE_MONITORS;
    payload: NoiseMonitor[];
  }
  | {
    type: NmtActionType.ADD_NOISE_SAMPLE;
    payload: StreamedNoiseSample[];
  }
  | {
    type: NmtActionType.PRUNE_OLD_NOISE_DATA;
    payload: number;
  };
