import React, { useEffect } from 'react';
import {
  DebugPanel,
  PlaybackControl,
  FlightLayer,
  FlightTailLayer,
  NmtLayer,
  RealtimeConnections,
} from 'src/@realtime/components';

import { useGoLive, useTimeProgression } from 'src/@realtime/hooks/playback';
import { PlaybackStatus, usePlaybackContext } from 'src/@realtime/contexts';

export const RealtimeLayers: React.FC = () => {
  const {
    state: { timestamps, playbackStatus },
    dispatch: playbackDispatch,
  } = usePlaybackContext();
  const goLive = useGoLive();
  useTimeProgression();
  // Start playback if we have enough data
  useEffect(() => {
    if (timestamps.length > 10 && playbackStatus === PlaybackStatus.Buffering) {
      goLive();
    }
  }, [timestamps, playbackDispatch]);

  return (
    <>
      <RealtimeConnections />
      <DebugPanel />
      <NmtLayer />
      <PlaybackControl />
      <FlightTailLayer />
      <FlightLayer />
    </>
  );
};
