import { HubConnection } from '@microsoft/signalr';
import React, { createContext, useContext, useRef, useState } from 'react';

// Enum for connection types
export enum RealtimeConnectionType {
  Flight = 'flight',
  Noise = 'noise',
}

interface AuthStoreContextType {
  flightConnection: HubConnection | null;
  noiseConnection: HubConnection | null;
  setConnection: (connection: HubConnection | null, type: RealtimeConnectionType) => void;
}

const RealtimeConnectionContext = createContext<AuthStoreContextType | null>(null);

// Provider Component
export const RealtimeConnectionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const flightConnectionRef = useRef<HubConnection | null>(null);
  const noiseConnectionRef = useRef<HubConnection | null>(null);

  const [flightConnection, setFlightConnection] = useState<HubConnection | null>(null);
  const [noiseConnection, setNoiseConnection] = useState<HubConnection | null>(null);

  const setConnection = (connection: HubConnection | null, type: RealtimeConnectionType) => {
    if (type === RealtimeConnectionType.Flight) {
      flightConnectionRef.current = connection;
      setFlightConnection(connection);
    } else {
      noiseConnectionRef.current = connection;
      setNoiseConnection(connection);
    }
  };

  return (
    <RealtimeConnectionContext.Provider
      value={{ flightConnection, noiseConnection, setConnection }}>
      {children}
    </RealtimeConnectionContext.Provider>
  );
};

// Custom hook to use the context
export const useRealtimeConnectionContext = () => {
  const context = useContext(RealtimeConnectionContext);
  if (!context) {
    throw new Error(
      'useRealtimeConnectionContext must be used within a RealtimeConnectionProvider'
    );
  }
  return context;
};
