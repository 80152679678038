import React from 'react';
import { TimeDisplay } from '../timeDisplay';
import { ControlButtonsWrapper, ControlButtonContainer, LiveButton } from './styles';
import { usePlaybackContext } from 'src/@realtime/contexts';
import { useGoLive } from 'src/@realtime/hooks';

export const ControlButtons: React.FC = ({}) => {
  const goLive = useGoLive();
  const {
    state: { currentTimestamp, isLive, maxPlayableTimestamp },
  } = usePlaybackContext();

  return (
    <ControlButtonsWrapper>
      <TimeDisplay current={currentTimestamp} max={maxPlayableTimestamp} />
      <ControlButtonContainer>
        {/* Historical currently disabled */}
        {/* <ControlButton className="skip-back" size="small">
          <FaChevronLeft />
        </ControlButton>
        <ControlButton className="skip-forward" size="small">
          <FaChevronRight />
        </ControlButton>
        <ControlButton className="speed" size="large">
          90x
        </ControlButton> */}
        <LiveButton disabled={isLive} className="live" size="large" onClick={goLive}>
          LIVE
        </LiveButton>
      </ControlButtonContainer>
    </ControlButtonsWrapper>
  );
};
