import { LineLayer } from 'react-map-gl';
import { RealtimeLayerIds } from 'src/@realtime/constants';

const LINE_WIDTH = 2;

// Base layout and type, fully defined
const baseLineLayer: Pick<LineLayer, 'type' | 'layout'> = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
};

// Specific tail styles extending the base properties
export const selectedTailStyle = (byAltitude: boolean): LineLayer => {
  const colorProperty = byAltitude ? 'color' : 'operationColor';

  return {
    ...baseLineLayer,
    id: RealtimeLayerIds.defaultTail,
    paint: {
      'line-color': ['get', colorProperty],
      'line-width': LINE_WIDTH,
    },
  };
};

export const selectedHiddenTailStyle: LineLayer = {
  ...baseLineLayer,
  id: RealtimeLayerIds.selectedHiddenTail,
  paint: {
    'line-color': 'white',
    'line-width': LINE_WIDTH * 3,
    'line-opacity': 0,
  },
};

export const defaultTailStyle: LineLayer = {
  ...baseLineLayer,
  id: RealtimeLayerIds.selectedTail,
  paint: {
    'line-color': ['get', 'operationColor'],
    'line-width': LINE_WIDTH,
    'line-dasharray': [1, 2],
  },
};
