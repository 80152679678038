import { CircleLayer } from 'react-map-gl';
import { RealtimeLayerIds } from 'src/@realtime/constants';
import styled from 'styled-components/macro';

export const selectedAircraftCircleStyle: CircleLayer = {
  id: RealtimeLayerIds.selectedAircraftCircle,
  type: 'circle',
  paint: {
    'circle-radius': 0, // Initial radius
    'circle-stroke-width': 0, // No stroke
    'circle-color': '#ffffff', // Filled white color
    'circle-opacity': 0.8, // Visible during animation
  },
};

export const FlightTagContentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  min-width: 100px;
`;

export const FlightTagContentsTailImg = styled.div`
  width: 18px;
  height: 18px; /* Fixed height for square appearance */

  img {
    width: 100%;
    heigh: auto;
  }
`;

export const FlightTagContentsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
`;
