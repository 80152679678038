export const getAltitudeColor = (altitude: number): string => {
  if (altitude <= 0) {
    return '#FFFFFF';
  } // White at ground level
  if (altitude <= 50) {
    return interpolateColor(altitude, 0, 50, [255, 255, 255], [173, 216, 230]); // White → Light Blue
  }
  if (altitude < 5000) {
    return interpolateColor(altitude, 50, 5000, [173, 216, 230], [0, 0, 255]); // Light Blue → Blue
  }
  if (altitude < 10000) {
    return interpolateColor(altitude, 5000, 10000, [0, 0, 255], [0, 255, 0]); // Blue → Green
  }
  if (altitude < 20000) {
    return interpolateColor(altitude, 10000, 20000, [0, 255, 0], [255, 255, 0]); // Green → Yellow
  }
  if (altitude < 25000) {
    return interpolateColor(altitude, 20000, 25000, [255, 255, 0], [255, 165, 0]); // Yellow → Orange
  }
  return interpolateColor(altitude, 25000, 30000, [255, 165, 0], [255, 0, 0]); // Orange → Red
};

export const interpolateColor = (
  value: number,
  min: number,
  max: number,
  startColor: number[],
  endColor: number[]
): string => {
  const ratio = (value - min) / (max - min);

  const r = Math.round(startColor[0] + ratio * (endColor[0] - startColor[0]));
  const g = Math.round(startColor[1] + ratio * (endColor[1] - startColor[1]));
  const b = Math.round(startColor[2] + ratio * (endColor[2] - startColor[2]));

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};
