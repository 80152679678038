import React from 'react';
import { StyledTimeDisplay } from './styles';
import { playbackTimeFormatShort, getCurrentTime } from 'src/utils';

interface TimeDisplayProps {
  current: number;
  max: number;
}

export const TimeDisplay: React.FC<TimeDisplayProps> = ({ current, max }) => (
  <StyledTimeDisplay>
    <span className="current-time">{playbackTimeFormatShort(getCurrentTime(current))}</span> /
    <span className="max-time">{playbackTimeFormatShort(getCurrentTime(max))}</span>
  </StyledTimeDisplay>
);
