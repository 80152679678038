import { displayToast } from '@ems/client-design-system';
import { RealtimeConnectionType } from 'src/@realtime/contexts';

const REALTIME_TOAST_ID = 'realtimeConnectionErrorToast';
const TOAST_TIMEOUT_LONG = 100000;
const TOAST_TIMEOUT_SHORT = 5000;

export const successToast = (type: RealtimeConnectionType): void => {
  displayToast({
    key: `${REALTIME_TOAST_ID}_${type}`,
    message: `${type.charAt(0).toUpperCase() + type.slice(1)} connection established`,
    intent: 'success',
    timeout: TOAST_TIMEOUT_SHORT,
  });
};

export const reconnectToast = (type: RealtimeConnectionType): void => {
  displayToast({
    key: `${REALTIME_TOAST_ID}_${type}`,
    message: `${type.charAt(0).toUpperCase() +
      type.slice(1)} connection error occurred, attempting to reconnect`,
    intent: 'warning',
    timeout: TOAST_TIMEOUT_LONG,
  });
};

export const failedToast = (type: RealtimeConnectionType): void => {
  displayToast({
    key: `${REALTIME_TOAST_ID}_${type}`,
    message: `${type.charAt(0).toUpperCase() +
      type.slice(1)} connection error occurred, please refresh`,
    intent: 'danger',
    timeout: TOAST_TIMEOUT_LONG,
  });
};
